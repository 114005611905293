<template>
  <header class="header">
    <v-container
      class="d-flex justify-space-between align-center header__wrap pb-0"
    >
      <div
        class="burger mr-md-3 d-block d-lg-none flex-shrink-0"
        :class="{ active: menu }"
        @click="toggle"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <router-link to="/" class="logo ml-4">
        <svg
          class="d-none d-md-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 426.68 100"
        >
          <g>
            <path
              fill="currentColor"
              d="M160.54,49a11.8,11.8,0,0,0,4.53-4.5,14.42,14.42,0,0,0,1.57-7.11c0-5.19-1.88-9.2-5.65-12s-8.84-4.2-15.21-4.2H106.36V78h39.42a29.22,29.22,0,0,0,11-1.9,15.89,15.89,0,0,0,7.25-5.56,15.25,15.25,0,0,0,2.57-9A14.8,14.8,0,0,0,160.54,49Zm-15.6,20.14H115.31v-16h30.11c4.39,0,7.55.74,9.46,2.21s2.87,3.9,2.87,7.29Q157.75,69.11,144.94,69.11Zm10.34-26.43c-1.74,1-5,1.52-9.86,1.52H115.31V30.05h29.63q12.93,0,12.93,6.47C157.87,39.62,157,41.68,155.28,42.68Z"
            />
            <path
              fill="currentColor"
              d="M233,21.16,191.2,65.9V26q0-5.68-4.47-5.68t-4.47,5.38V78h8.64l41.54-44.25V73.16q0,5.69,4.47,5.68t4.41-5.38V21.16Z"
            />
            <path
              fill="currentColor"
              d="M317.13,30.08a15.62,15.62,0,0,0-9-6.86q-6.16-2.05-16.27-2.06H266a9.2,9.2,0,0,1,2.42-6.83,14.57,14.57,0,0,1,6.92-3.54,63.25,63.25,0,0,1,10.79-1.54q2.06-.12,3.93-.18t4-.09l5.05-.06q2.89,0,6.95,0a7.93,7.93,0,0,0,4.51-1.06,3.66,3.66,0,0,0,1.54-3.24q0-4.59-5.69-4.59-7.36,0-14,.21c-4.39.14-8.14.4-11.25.76a43.94,43.94,0,0,0-14.05,3.32,16.31,16.31,0,0,0-7.8,7,23.58,23.58,0,0,0-2.45,11.39V55.74q0,8.41,2.9,13.28t9.46,6.92q6.57,2.06,17.32,2h3.63q10.83,0,17.35-2T317,69.14q2.9-4.81,2.9-13.15V43.17Q319.94,34.89,317.13,30.08Zm-6.08,25.66c0,3.79-.52,6.65-1.57,8.56s-3.07,3.19-6.05,3.84a67.28,67.28,0,0,1-13.18,1h-3.63a67,67,0,0,1-13.12-1q-4.47-1-6-3.84c-1-1.91-1.57-4.77-1.57-8.56V30.05h26a57.2,57.2,0,0,1,11.94.94q4.14.93,5.69,3.8t1.54,8.62Z"
            />
            <path
              fill="currentColor"
              d="M423.78,30q-2.91-4.82-9.4-6.83t-17.33-2h-3.5a39.74,39.74,0,0,0-13.18,2,21.4,21.4,0,0,0-7.8,4.72v-1a9.73,9.73,0,0,0-1-5,3.62,3.62,0,0,0-3.32-1.51q-4.54,0-4.54,5.26V94.44q0,5.57,4.35,5.56,4.55,0,4.54-5.56V78H397.3q10.69,0,17.14-2t9.34-6.83q2.89-4.81,2.9-13.15V43.17Q426.68,34.83,423.78,30Zm-6,25.72c0,3.79-.51,6.65-1.54,8.56s-3,3.19-5.95,3.84a65.62,65.62,0,0,1-13,1H372.63V49.4q0-9.74,5.14-14.54t15.78-4.81h3.5a67.71,67.71,0,0,1,13.06.94c3,.62,5,1.88,6.08,3.77s1.6,4.78,1.6,8.65Z"
            />
            <path
              fill="currentColor"
              d="M345.14,21.74a5.93,5.93,0,0,1,1.17,3.9v48q0,5.2-4.53,5.2t-4.41-5.2v-48a5.83,5.83,0,0,1,1.2-3.9,4.51,4.51,0,0,1,6.57,0Zm-3.3-9.15a6.29,6.29,0,1,0-6.29-6.29A6.29,6.29,0,0,0,341.84,12.59Z"
            />
            <rect
              fill="currentColor"
              x="71.75"
              y="44.2"
              width="20.5"
              height="8.95"
              rx="4.47"
            />
          </g>
          <g>
            <path
              fill="currentColor"
              d="M54.91,27.18A10.67,10.67,0,0,0,53,25.31a23.41,23.41,0,0,0-2.23-1.45c-3.52-1.81-8-2.7-13.5-2.7H23.35q-11.85,0-17.6,5.78T0,44.64v9.81q0,12,5.77,17.72t17.7,5.75H50.54a5.08,5.08,0,0,0,3.57-1.47,5,5,0,0,0,1.47-3.54,4.91,4.91,0,0,0-.15-1.22,5,5,0,0,0-4.89-3.82H23.47c-5.2,0-8.72-.93-10.59-2.79s-2.83-5.4-2.83-10.63V44.64q0-7.78,2.79-10.61t10.51-2.81H37.24c14.52,0,17.46,4.3,19.42,7a8.83,8.83,0,0,1,1,1.76,13.19,13.19,0,0,0,.54-4.08A14.08,14.08,0,0,0,54.91,27.18Z"
            />
            <path
              fill="currentColor"
              d="M53.53,39.14a5,5,0,0,0-7-1.11L31.56,48.91l-7.1-5.73A5,5,0,1,0,18.15,51l10.09,8.15a5,5,0,0,0,6.12.15L52.42,46.17A5,5,0,0,0,53.53,39.14Z"
            />
          </g>
        </svg>

        <svg
          class="mini_logo d-md-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 61.52 60"
        >
          <g>
            <path
              fill="currentColor"
              d="M58.05,6.37a11.38,11.38,0,0,0-2.06-2,25.39,25.39,0,0,0-2.36-1.53Q48.05,0,39.36,0H24.68Q12.15,0,6.08,6.12T0,24.82V35.2Q0,47.86,6.1,53.92T24.8,60H53.42a5.29,5.29,0,0,0,3.77-1.56,5.21,5.21,0,0,0,1.56-3.73,5.43,5.43,0,0,0-.16-1.29,5.34,5.34,0,0,0-5.17-4H24.8c-5.49,0-9.21-1-11.19-3s-3-5.71-3-11.23V24.82q0-8.24,3-11.21c2-2,5.67-3,11.11-3H39.36c15.36,0,18.46,4.54,20.53,7.39A9.09,9.09,0,0,1,61,19.89a13.8,13.8,0,0,0,.57-4.31A14.86,14.86,0,0,0,58.05,6.37Z"
            />
            <path
              fill="currentColor"
              d="M56.59,19a5.32,5.32,0,0,0-7.43-1.17L33.36,29.33l-7.5-6a5.32,5.32,0,0,0-6.68,8.28l10.68,8.61a5.3,5.3,0,0,0,6.46.16l19.1-13.89A5.33,5.33,0,0,0,56.59,19Z"
            />
          </g>
        </svg>
      </router-link>

      <div class="d-flex align-center">
        <div class="user_block d-flex align-center">
          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" fab small text @click="showPdfPublicContract = true">
                <v-img height="40px" width="20px" src="@/assets/icon_contract.svg"/>
              </v-btn>
            </template>
            <span>Публічний Договір</span>
          </v-tooltip>

          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab small text class="mx-2" @click="downloadFile('https://land.e-vybir.ua/files/bid.xlsx', '2023.002 - Заява-приєднання до умов Публічного Договору, АКТ.xlsx')">
                <v-img height="40px" width="20px" src="@/assets/icon_download.svg"/>
              </v-btn>
            </template>
            <span>Заява-приєднання</span>
          </v-tooltip>
          <v-tooltip bottom z-index="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab small text color="white" @click.stop="showInstruction">
                <v-icon size="45">mdi-help-circle-outline</v-icon></v-btn>
            </template>
            <span>Загальна інформація</span>
          </v-tooltip>
          <div class="user_menu_item ml-5" v-if="!isAuthed">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!--                <v-icon  v-bind="attrs" v-on="on" @click="modal ('login')">mdi-account-outline</v-icon>-->
                <router-link :to="{ name: 'Login' }">
                  <v-icon v-bind="attrs" v-on="on">mdi-account-outline</v-icon>
                </router-link>
              </template>
              <span>Увійти</span>
            </v-tooltip>
          </div>

          <div class="user_menu_item ml-5" v-if="isAuthed">
            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-img class="header_user_pic" :src="getUserAvatar"></v-img>
                </div>
              </template>

              <v-list>
                <v-list-item
                  v-for="(userCabinetItem, index) in userCabinetItems"
                  :key="index"
                >
                  <v-list-item-title>
                    <router-link
                      class="menu_item"
                      :to="{ name: userCabinetItem.link }"
                    >
                      <v-icon class="mr-2">{{ userCabinetItem.icon }}</v-icon>
                      {{ userCabinetItem.title }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>
                    <div class="menu_item">
                      <v-icon class="mr-2">mdi-exit-to-app</v-icon>
                      Вийти
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-container>
    <v-navigation-drawer v-model="menu" fixed left>
      <div class="mob_menu_header d-flex align-center">
        <router-link class="ml-4" :to="{ name: 'Login' }" v-if="!isAuthed">
          Увiйти
        </router-link>

        <router-link
          class="d-flex align-center pl-4"
          to="/account/info"
          v-if="isAuthed"
        >
          <v-img
            class="header_user_pic flex-grow-0 mr-2"
            :src="getUserAvatar"
          ></v-img>
          <span class="header_username">{{ fullName }}</span>
        </router-link>

        <div
          class="burger burger_nav ml-auto mr-3"
          :class="{ active: menu }"
          @click="toggle"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <v-divider />
      <v-list nav dense>
        <v-list-item-group v-if="isAuthed">
          <v-list-item
            v-for="(userCabinetItem, index) in userCabinetItems"
            :key="index"
            link
            :to="{ name: userCabinetItem.link }"
          >
            <v-icon class="mr-4">{{ userCabinetItem.icon }}</v-icon>
            <v-list-item-title>{{ userCabinetItem.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-icon class="mr-4">mdi-exit-to-app</v-icon>
            <v-list-item-title>Вийти</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      max-width="800px"
      v-model="showPdfPublicContract"
      @keydown.esc="closeDetailDialog"
    >
      <iframe style="width: 100%;height: 800px; border: none;" src="https://land.e-vybir.ua/files/public-contract.pdf"/>
    </v-dialog>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { downloadFile } from '@/utils'

export default {
  name: 'HeaderComponent',
  data: () => ({
    token: '',
    menu: false,
    menuMobile: false,
    userCabinetItems: [
      {
        title: 'Персональні данi',
        link: 'Info',
        icon: 'mdi-account'
      },
      {
        title: 'Налаштування',
        link: 'AccountSettings',
        icon: 'mdi-cog'
      }
    ],
    downloadFile: downloadFile,
    showPdfPublicContract: false
  }),
  computed: {
    ...mapGetters('auth', ['isAuthed']),
    ...mapGetters('user', ['getUser']),
    fullName () {
      if (!this.getUser) return ''
      return `${this.getUser.firstName} ${this.getUser.lastName}`
    },
    getUserAvatar () {
      if (!this.getUser) return ''
      return (
        this.getUser.avatar || 'https://randomuser.me/api/portraits/men/85.jpg'
      )
    }
  },
  methods: {
    ...mapMutations('map', ['setDialogInstructionState']),
    toggle () {
      this.menu = !this.menu
    },
    logout () {
      this.$store.commit('auth/logOut')
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie))
      this.$store.commit('user/resetPrivacyState')
      this.$store.commit('map/resetPrivacyState')
      this.$router.push('/').catch(() => {})
    },
    showInstruction () {
      this.setDialogInstructionState(true)
    }
  }
}
</script>
