import axios from 'axios'

export const downloadFile = (url, filename) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob' // Set response type to blob for file download
  })
    .then(response => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch(error => {
      console.error('Error downloading file:', error)
    })
}
